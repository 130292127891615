/**
 *
 * This file is NOT generated yet, but it should be generated
 *
 * Add all kinds of types that we should actually generate instead of defining by ourselves
 * like graphql types or openapi spec types
 */

export type OrganizationBranding = {
  logo_url: string;
  colors: string[];
};

export type OrganizationMember = {
  user_id: string;
  role_name: string;
  picture: string;
  name: string;
  email: string;
  created: string;
};

export type PaginatedList<T extends Record<string, unknown>> = {
  start: number;
  limit: number;
  length: number;
  total: number;
  next: string;
} & T;

export type ProjectKey = {
  projectId: string;
  organizationId: string;
  encKey: string;
};

export type ProjectMember = {
  accountId: string;
  projectId: string;
  publicKey: string;
};

export type MemberProjectKey = {
  accountId: string;
  projectId: string;
  encSymmetricKey: string;
};

export type ResponseGetMyProjectKeys = {
  projectKeys: ProjectKey[];
  members: ProjectMember[];
};

export type ResponseGetAllMyProjectKeys = {
  projectKeys: ProjectKey[];
};

export type Whoami = {
  sessionAge: number;
  accountId: string;
  email: string;
  firstName: string;
  lastName: string;
  created: number;
  publicKey: string;
  encSymmetricKey: string;
  encPrivateKey: string;
  saltEnc: string;
  isPaymentRequired: boolean;
  isTrialing: boolean;
  isVerified: boolean;
  isAdmin: boolean;
  trialEnd: string;
  planName: string;
  planId: string;
  canManageTeams: boolean;
  maxTeamMembers: number;
  passwordSet: boolean;
  canUnsetPassword: boolean;
  unsubscribedFromEmails: boolean;
  customerId: string;
  subscriptionId: string;
  encDriverKey: string;
  encryptionEnabled: boolean;
};

export type InviteRule = {
  domainId: string;
  domain: string;
  verified: boolean;
};

export type InviteRuleOption = 'all' | 'onlyVerified' | 'custom';
export type OrgInviteRule = {
  domains: InviteRule[];
  isOverriding: boolean;
  name: string;
  rule: InviteRuleOption;
  orgId: string;
  orgRuleId: string;
};

export type EntInviteRule = {
  domains: InviteRule[];
  rule: InviteRuleOption;
  enabled: boolean;
  orgRuleId: string;
};

export type EmailViolation = {
  email: string;
  orgId: string;
};

export type EmailRuleViolation = {
  members: EmailViolation[];
  invites: EmailViolation[];
  totalMemberViolations: number;
  totalInviteViolations: number;
};

export type EntInviteViolations = {
  entViolations: EmailRuleViolation[];
  orgViolations: Record<string, EmailRuleViolation>;
};

export enum EnterpriseRoleEnum {
  Owner = 'owner',
  CoOwner = 'co-owner',
  Billing = 'billing-only',
  Member = 'member',
  OwnAccount = 'your own account',
}

export type ResponseScimStarter = {
  publicKey: string;
  keys: ProjectKey[];
  encryptedKey: string;
};

export type ResponseScimFinisher = {
  scimAuthToken: string;
};

export type ScimLog = {
  id: string;
  requestMethod: string;
  requestUrl: string;
  requestProto: string;
  requestBody: null;
  requestHost: string;
  requestRemoteAddr: string;
  requestUserAgent: string;
  requestCreated: string;
  responseStatus: number;
  responseBody: object | null;
};

export type FeatureName = 'scim2';
export type FeatureMap = Record<
  FeatureName,
  {
    featureId: string;
    isAvailable: boolean;
    isEnabled: boolean;
  }
>;
